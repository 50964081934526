import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ShopPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.designerae.com/shop/"}
        />
        <Grid
          className={"custom-page-shop custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"wcjpvfqysxzunbzm"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"obplplxaykdgzuek"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "f135cfe1-06b1-40a4-8563-72b241891f17",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"hasowseugujcbipw"}
              style={{ padding: 16 }}
              width={7}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "86eb77c8-3ae3-49a7-b270-995379340329",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ztotzafkgohxofei"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"puregncnygjnzxwi"}
              style={{ padding: 16 }}
              width={16}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e7e2c0d0-4987-4f34-b8f3-bea8753452d4",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"iemsyznxawdcjocc"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"qysozpdiycrnzgvp"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "f6dfac2d-6df5-4735-91e7-590c2675d4a2",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"debmwgjvtztxpndg"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c7173b23-8b64-4cca-83aa-f5987d7ca46e",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"utnrzidzwrtxzvpx"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "7307ca9c-b83e-4172-9c72-e9bd6c51e293",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"pgoymnjuwmkkijks"}
            style={{ padding: "50px", background: "rgba(155, 155, 155, 0.2)" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"atmozfvsoyltyqig"}
              style={{ padding: "32px" }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "89a0f209-60eb-4e76-8a19-920e07cc0703",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"gjhgofvmvozrsfch"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"bakimskvjgudukrt"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "98921b63-efc3-4aa0-9e5a-9bfd6d0c454e",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"lwdeoiljvzyjbwyy"}
            style={{ padding: 16, background: "rgba(155, 155, 155, 0.2)" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"zcorlxlgputarvad"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "d0bb5995-2eda-491f-be22-9bfe0ecf6848",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"nqxtgnskgbtzqarb"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "76c8c7d5-c892-4a48-a58c-53939c105c10",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"zbjqiiirnqfhfkao"}
            style={{ padding: "32px" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"lbbduwbatepxcnym"}
              style={{ padding: 16 }}
              width={6}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "bab04d53-4831-487c-8a82-c5ae0205c2bf",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"sxsvxvexefhyugzw"}
              style={{ padding: 16 }}
              width={2}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"cbkqbwycnmxsepyi"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "76b19a34-b820-43b5-9f58-8e5d8f25ecc3",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27099 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
